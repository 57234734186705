<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="query.utilData"
              placeholder="활용데이터"
              class="el-def"
              style="width: 200px;"
            />
            <b-form-datepicker
              id="datepicker-placeholder1"
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              id="datepicker-placeholder2"
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.isInfoPopUp"
              :options="codes.useTypeFixed"
              class="invoice-filter-select el-def"
              placeholder="처방 팝업 알림"
              :reduce="(option) => option.code"
              style="width: 200px"
            />
            <b-form-input
              v-model="query.title"
              class="el-def"
              placeholder="제목"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              메디컬트렌드 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="liveInfoId"
        :items="medicalList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedMedical"
      >
        <template #cell(liveInfoId)="data">
          {{ data.value }}
        </template>

        <template #cell(utilData)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>

        <template #cell(isInfoPopUp)="data">
          <span class="text-nowrap">
            {{ data.value ? 'O' : 'X' }}
          </span>
        </template>
        <template #cell(startDate)="data">
          {{ data.value | $dateFormatter('YYYY-MM-DD') }}
        </template>
      </b-table>
    </b-card>

    <validation-observer ref="formRules">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start"
            >
              <feather-icon
                icon="EditIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                상세
              </h4>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="outline-primary"
                @click.prevent="resetMedicalInfoData()"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  class="mr-50"
                />
                <span>신규 등록</span>
              </b-button>
              <b-button
                :disabled="isSaving"
                variant="primary"
                class="ml-1"
                @click.prevent="saveMedical()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                <span>저장</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
                @click.prevent="removeMedical()"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>삭제</span>
              </b-button>
            </b-col>
          </b-row>

          <!-- Edit Form -->
          <b-row class="mt-1">
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="제목">
                <validation-provider
                  #default="{ errors }"
                  name="제목"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="medicalData.title"
                    placeholder="입력하세요"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="사용여부"
                label-for="liveinfo-is-use"
                rules="required"
              >
                <b-form-radio-group
                  id="liveinfo-is-use"
                  v-model="medicalData.isUse"
                  value-field="code"
                  text-field="label"
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="활용데이터">
                <b-form-input
                  id="titleType"
                  v-model="medicalData.utilData"
                  placeholder="활용 데이터를 입력하세요"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="검색키워드">
                <b-form-input
                  id="title"
                  v-model="medicalData.searchKeyWord"
                  placeholder="키워드를 입력하세요"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="컨텐츠"
                label-for=""
              >
                <b-form-file
                  ref="contentFile"
                  accept="text/plain,.html"
                  drop-placeholder="Drop file here..."
                  @input="readContentFile"
                />
                <b-form-input
                  v-show="false"
                  v-model="medicalData.htmlContent"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                variant="primary"
                class="mr-sm-50"
                style="margin-top: 28px"
                :disabled="medicalData.htmlContent === null"
                @click="showModal = true"
              >
                <span>미리보기</span>
              </b-button>
              <b-modal
                id="medicalPreview"
                v-model="showModal"
                title="HTML 미리보기"
              >
                <iframe
                  ref="previewFrame"
                  :srcdoc="medicalData.htmlContent"
                  frameborder="0"
                  max-width="1150px"
                  width="1115px"
                  height="1000px"
                  style="background-color: white;"
                />
              </b-modal>
              <b-button
                v-if="medicalData.liveInfoId && medicalData.htmlContent != null"
                variant="outline-dark"
                class="mr-sm-50"
                style="margin-top: 28px"
                @click="downloadContent"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                <span>다운로드</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Main 썸네일 (※ 이미지 사이즈는 570 * 390 입니다)"
                label-for="main-thumbnail-files"
              >
                <b-form-file
                  ref="mainImgFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  @input="uploadMainImage"
                />
                <p>
                  {{ medicalFiles.mainImgFileName }}
                </p>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Sub 썸네일 (※ 이미지 사이즈는 640 * 140 입니다)"
                label-for="sub-thumbnail-files"
              >

                <b-form-file
                  ref="subImgFile"
                  v-model="medicalFiles.subImgFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  @input="uploadSubImage"
                />
                <p>
                  {{ medicalFiles.subImgFileName }}
                </p>

              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="약품정보 Banner 썸네일 (※ 이미지 사이즈는 494 * 253 입니다)"
                label-for="banner-thumbnail-files"
              >

                <b-form-file
                  ref="bannerImgFile"
                  v-model="medicalFiles.bannerImgFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  @input="uploadBannerImage"
                />
                <p>
                  {{ medicalFiles.bannerImgFileName }}
                </p>

              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Main text">
                <validation-provider
                  #default="{ errors }"
                  name="Main text"
                  rules="required"
                >
                  <b-form-input
                    v-model="medicalData.mainText"
                    tpye="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="공백포함 60자까지 입력 가능합니다."
                    maxlength="60"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label="Sub text">
                <validation-provider
                  #default="{ errors }"
                  name="Sub text"
                  rules="required"
                >
                  <b-form-input
                    v-model="medicalData.subText"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="공백포함 70자까지 노출됩니다."
                    maxlength="73"
                    @input="replaceText"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="메인 게시여부"
                class="mt-1 mb-2"
                style="margin-right: 50px"
              >
                <b-form-radio-group
                  v-model="medicalData.isMainContent"
                  name="isMainContent"
                >
                  <b-form-radio
                    class="custom-control-primary"
                    value="true"
                  >
                    게시
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    value="false"
                  >
                    게시 안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
              class="mb-2"
            >
              <b-form-group
                style="margin-right: 50px"
              >
                <label>댓글 기능</label>
                <b-form-radio-group
                  v-model="medicalData.isComment"
                  name="isComment"
                >
                  <b-form-radio
                    class="custom-control-primary"
                    :value="true"
                  >
                    사용
                  </b-form-radio>
                  <b-form-radio
                    class="custom-control-secondary"
                    :value="false"
                  >
                    사용안함
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col
              v-if="medicalData.isComment"
              cols="12"
              md="5"
            >
              <b-form-group>
                <label for="comment-survey-title">투표형 댓글 투표지 선택</label>
                <b-badge
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.right
                  variant="light-secondary"
                  style="padding: 0px; margin-left: 5px;"
                  title="지정: 투표형 댓글 / 미지정: 일반 댓글"
                >
                  <feather-icon
                    icon="InfoIcon"
                  />
                </b-badge>
                <label
                  v-if="medicalData.isAnswer"
                  for="comment-survey-title"
                  style="margin-left: 15px; color: #ea5455"
                >참여자가 있어 다른 투표지를 등록할 수 없습니다.</label>
                <b-form-input
                  id="comment-survey-title"
                  v-model="medicalData.commentSurveyTitle"
                  readonly
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="medicalData.isComment"
              cols="12"
              md="4"
              class="mb-50"
            >
              <b-button
                :disabled="medicalData.isAnswer"
                variant="outline-primary"
                class="mt-0 mt-md-2"
                @click.prevent="showCommentSurveySelect = true"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span>투표지 검색</span>
              </b-button>
              <b-button
                v-if="medicalData.commentSurveyId"
                variant="outline-danger"
                class="ml-1 mt-0 mt-md-2"
                @click.prevent="medicalData.commentSurveyId = null; medicalData.commentSurveyTitle = null; "
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>투표지 제거</span>
              </b-button>
            </b-col>

          </b-row>
        </div>
      </b-card>
      <!-- 댓글 가져오기 -->
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="19"
            />
            <h4
              class="mb-0 ml-50"
              style="min-width: 120px"
            >
              댓글 가져오기
            </h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              :disabled="!medicalData.commentCopyDate"
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="removeCopyComments()"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>초기화</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="6"
            md="6"
          >
            <b-form-group
              label="컨텐츠 선택"
              label-for="medical-banner-target-dept"
            >
              <b-form-input
                id="medical-banner-target-dept"
                v-model="medicalData.commentCopyContentTitle"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mt-50"
          >
            <b-button
              variant="outline-primary"
              @click.prevent="showCopyCommentContentSelect = true"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
            <b-button
              v-if="medicalData.commentCopyContentId && !medicalData.commentCopyDate"
              variant="primary"
              class="ml-1"
              @click.prevent="copyComments()"
            >
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
              <span>가져오기</span>
            </b-button>
            <label
              v-if="medicalData.commentCopyDate"
              class="ml-1"
            >(가져오기완료: {{ medicalData.commentCopyDate }})</label>
          </b-col>
        </b-row>
      </b-card>
      <!-- 약품정보 배너 설정 사용 -->
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="TargetIcon"
              size="19"
            />
            <h4
              class="mb-0 ml-50"
              style="min-width: 120px"
            >
              타겟 진료과
            </h4>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="10">
            <b-form-group
              label="진료과"
              label-for="medical-banner-target-dept"
            >
              <validation-provider
                #default="{ errors }"
                name="진료과"
                rules="required"
              >
                <b-form-input
                  id="medical-banner-target-dept"
                  v-model="medicalData.bannerTargetDeptNames"
                  readonly
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showBannerTargetDeptSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>

    <live-info-form
      :liveinfo-id="medicalData.liveInfoId"
      :content-type="2"
      :clear-flag="liveInfoFormClearFlag"
      :valid-flag="liveInfoFormValidFlag"
      @callback="liveInfoForm.callback"
    />

    <medi-dept-select
      :show-medi-dept-select="showBannerTargetDeptSelect"
      :selected-value="medicalData.bannerTargetDeptCodes"
      @close="bannerTargetDeptSelect.close"
    />

    <copy-comment-content-select
      :show-content-select="showCopyCommentContentSelect"
      @close="copyCommentContentSelect.close"
    />
    <comment-survey-select
      :show-comment-survey-select="showCommentSurveySelect"
      @close="commentSurveySelect.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import {
  ref, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormFile, BBadge, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { errorFormatter, arrayJoinStr } from '@core/utils/filter'
import { saveAs } from "file-saver"
import MediDeptSelect from '@/views/apps/popup/MediDeptSelect.vue'
import LiveInfoForm from '../LiveInfoForm.vue'
import CopyCommentContentSelect from './CopyCommentContentSelect.vue'
import CommentSurveySelect from "../comment-survey-manage/CommentSurveySelect.vue"

export default {
  components: {
    vSelect,
    LiveInfoForm,
    MediDeptSelect,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BBadge,
    CopyCommentContentSelect,
    CommentSurveySelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Main Table Config
    const medicalInitState = {
      liveInfoId: null,
      utilData: null,
      title: null,
      isUse: true,
      mainText: null,
      subText: null,
      isComment: true,
      isMainContent: true,
      htmlContent: null,
      mainImgFileId: null,
      subImgFileId: null,
      bannerImgFileId: null,
      // 약품정보-메디컬트렌드 배너 타겟 진료과
      bannerTargetDeptCodes: null,
      bannerTargetDeptNames: null,
      searchKeyWord: null,
      commentCopyContentId: null,
      commentCopyDate: null,
      commentCopyContentTitle: null,
      liveInfo: null,

      commentSurveyId: null,
      commentSurveyTitle: null,
      isAnswer: false,
    }

    const medicalData = ref({ ...medicalInitState })

    const medicalFileInitState = {
      mainImgFileId: 0,
      mainImgFileSrc: '',
      mainImgFileName: '',

      subImgFileId: 0,
      subImgFileSrc: '',
      subImgFileName: '',

      bannerImgFileId: 0,
      bannerImgFileSrc: '',
      bannerImgFileName: '',
    }

    const medicalFiles = ref({ ...medicalFileInitState })

    const resetMedicalInfoData = () => {
      contentFile.value.reset()
      mainImgFile.value.reset()
      subImgFile.value.reset()
      bannerImgFile.value.reset()
      Object.assign(medicalData.value, medicalInitState)
      Object.assign(medicalFiles.value, medicalFileInitState)
      refs.formRules.reset()
      liveInfoFormClearFlag.value = !liveInfoFormClearFlag.value
    }

    const showCopyCommentContentSelect = ref(false)
    const copyCommentContentSelect = {
      close: rs => {
        showCopyCommentContentSelect.value = false
        if (rs) {
          medicalData.value.commentCopyContentId = rs.id
          medicalData.value.commentCopyContentTitle = rs.title
          medicalData.value.commentCopyDate = null // 컨텐츠를 변경한 경우 완료일시는 null로 삽입
        }
      },
    }

    // Set Codes
    const codes = ref({
      useTypeFixed: [],
    })

    const fetchCodes = () => {
      axios
        .get('/fa/medical/codes')
        .then(rs => {
          const { useTypeFixed } = rs.data
          codes.value.useTypeFixed = useTypeFixed
          refetchData()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '코드 데이터를 불러오는데 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      liveInfoId: null,
      startDate: null,
      endDate: null,
      isMoaView: null,
      isInfoPopUp: null,
      title: null,
    })

    const contentFile = ref(null)
    const mainImgFile = ref(null)
    const subImgFile = ref(null)
    const bannerImgFile = ref(null)
    const showModal = ref(false)
    const dateFormatter = value => dayjs(new Date(value)).format('YY-MM-DD')
    const medicalList = ref([])
    const totalRecords = ref(0)
    const isSaving = ref(false)
    const liveInfoFormValidFlag = ref(false)
    const liveInfoFormClearFlag = ref(false)
    const liveInfoForm = {
      callback: rs => {
        if (!rs) return

        const medical = medicalData.value
        const liveInfo = rs
        liveInfo.title = medical.title
        liveInfo.isUse = medical.isUse
        liveInfo.contentType = 2 // MedicalTrend
        liveInfo.htmlContent = medical.htmlContent
        medicalData.value.liveInfo = liveInfo

        if (medicalData.value.htmlContent === null) {
          pushToast('warning', '컨텐츠를 등록하세요.')
          return
        }
        if (!medicalData.value.mainImgFileId) {
          pushToast('warning', '메인 썸네일 이미지가 선택되지 않았습니다.')
          return
        }
        if (!medicalData.value.subImgFileId) {
          pushToast('warning', '서브 썸네일 이미지가 선택되지 않았습니다.')
          return
        }
        if (!medicalData.value.bannerImgFileId) {
          pushToast('warning', '약품정보 배너 썸네일 이미지가 선택되지 않았습니다.')
          return
        }

        const duplicateNoticeTimes = new Set()
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < medicalData.value.liveInfo.schedules.length; i++) {
          if (medicalData.value.liveInfo.schedules[i].noticeTime !== null) {
            const currentNoticeTime = medicalData.value.liveInfo.schedules[i].noticeTime.substr(0, 5)

            if (duplicateNoticeTimes.has(currentNoticeTime) && (medicalData.value.liveInfo.isScheduleUse || !medicalData.value.liveInfo.isImmediate)) {
              pushToast('danger', '스케줄,처방알림 시간에 중복이 있습니다.')
              return
            }
            duplicateNoticeTimes.add(currentNoticeTime)
          }
        }

        // 댓글 사용안함인 경우 투표지 정보 제거
        if (!medicalData.value.isComment) {
          medicalData.value.commentSurveyId = null
          medicalData.value.commentSurveyTitle = null
        }

        if (medicalData.value.liveInfoId) {
          updateMedical()
        } else {
          saveNewMedical()
        }
      },
    }

    const tableColumns = [
      {
        key: 'liveInfoId', label: 'ID', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'utilData', label: '활용데이터', sortable: true, thStyle: { width: '13%' },
      },
      { key: 'title', label: '제목', sortable: true },
      {
        key: 'isInfoPopUp', label: '팝업알림', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'startDate', label: '게시시작일', sortable: true, thStyle: { width: '10%' },
      },
    ]

    const refetchData = () => {
      fetchMedicals()
    }

    const onRowSelectedMedical = items => {
      const item = items[0]
      if (item) {
        fetchMedical(item.liveInfoId)
      }
    }

    // Main Table Config End.
    const fetchMedical = id => {
      axios.get(`/fa/medical/${id}`)
        .then(rs => {
          const { data } = rs
          medicalData.value = data
          if (medicalData.value.commentSurveyId) {
            medicalData.value.commentSurveyTitle = `[${medicalData.value.commentSurveyId}] ${medicalData.value.commentSurveyTitle}`
          }

          if (rs.data.mainImgFileId) {
            fetchMainImageFile(rs.data.mainImgFileId)
          }
          if (rs.data.subImgFileId) {
            fetchSubImageFile(rs.data.subImgFileId)
          }
          if (rs.data.bannerImgFileId) {
            fetchBannerImageFile(rs.data.bannerImgFileId)
          } else if (rs.data.bannerImgFileId == null) {
            // 배너 이미지가 없는 경우(추가전 컨텐츠) 초기화
            medicalFiles.value.bannerImgFileId = 0
            medicalFiles.value.bannerImgFileSrc = ''
            medicalFiles.value.bannerImgFileName = ''
          }
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const uploadMainImage = file => {
      const formData = new FormData()
      formData.append('containerName', 'medicaltrend')
      formData.append('file', file)

      axios.post('/fa/attachfile/single',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.id) {
            medicalData.value.mainImgFileId = rs.data.id
            fetchMainImageFile(rs.data.id)
          } else {
            pushToast('danger', '파일 업로드에 실패하였습니다.')
          }
        })
        .catch(() => {
        })
    }

    const fetchMainImageFile = id => {
      axios.get(`/fa/attachfile/${id}`)
        .then(rs => {
          if (rs.data) {
            medicalFiles.value.mainImgFileName = rs.data.fileName
            medicalFiles.value.mainImgFileSrc = rs.data.filePath
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const uploadSubImage = file => {
      const formData = new FormData()
      formData.append('containerName', 'medicaltrend')
      formData.append('file', file)
      axios.post('/fa/attachfile/single',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.id) {
            medicalData.value.subImgFileId = rs.data.id
            fetchSubImageFile(rs.data.id)
          } else {
            pushToast('danger', '파일 업로드에 실패하였습니다.')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const fetchSubImageFile = id => {
      axios.get(`/fa/attachfile/${id}`)
        .then(rs => {
          if (rs.data) {
            medicalFiles.value.subImgFileName = rs.data.fileName
            medicalFiles.value.subImgFileSrc = rs.data.filePath
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const uploadBannerImage = file => {
      const formData = new FormData()
      formData.append('containerName', 'medicaltrend')
      formData.append('file', file)
      axios.post('/fa/attachfile/single',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(rs => {
          if (rs.data.id) {
            medicalData.value.bannerImgFileId = rs.data.id
            fetchBannerImageFile(rs.data.id)
          } else {
            pushToast('danger', '파일 업로드에 실패하였습니다.')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
    const fetchBannerImageFile = id => {
      axios.get(`/fa/attachfile/${id}`)
        .then(rs => {
          if (rs.data) {
            medicalFiles.value.bannerImgFileName = rs.data.fileName
            medicalFiles.value.bannerImgFileSrc = rs.data.filePath
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const showBannerTargetDeptSelect = ref(false)
    const bannerTargetDeptSelect = {
      close: rs => {
        showBannerTargetDeptSelect.value = false

        if (rs) {
          medicalData.value.bannerTargetDeptCodes = arrayJoinStr(rs, 'code')
          medicalData.value.bannerTargetDeptNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    // Set Codes End.
    const fetchMedicals = () => {
      axios
        .post('/fa/medical/list', {
          search: {
            query: query.value,
          },
          sort: {
            predicate: 'liveInfoId',
            reverse: true,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data
          medicalList.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const copyComments = () => {
      if (!medicalData.value.liveInfoId) {
        pushToast('warning', '댓글 가져오기는 컨텐츠 등록 후 실행 가능합니다.')
        return
      }

      axios
        .post('/fa/medical/copy-comments', {
          liveInfoId: medicalData.value.liveInfoId,
          targetContentId: medicalData.value.commentCopyContentId,
        })
        .then(rs => {
          const { completedDate } = rs.data
          console.log('completedDate', completedDate)
          medicalData.value.commentCopyDate = completedDate
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '댓글 가져오기 과정에서 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removeCopyComments = () => {
      if (!medicalData.value.liveInfoId) {
        pushToast('warning', '삭제할 대상을 선택하세요.')
        return
      }
      bvModal
        .msgBoxConfirm('이전에 가져온 댓글을 모두 삭제합니다. 진행 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios
            .delete(`/fa/medical/remove-copy-comments/${medicalData.value.liveInfoId}`)
            .then(() => {
              pushToast('warning', '데이터 삭제 완료')
              medicalData.value.commentCopyContentId = null
              medicalData.value.commentCopyDate = null
              medicalData.value.commentCopyContentTitle = null
            })
            .catch(error => {
              pushToast('danger', errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'))
            })
        })
    }

    const saveMedical = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return
        liveInfoFormValidFlag.value = !liveInfoFormValidFlag.value
      })
    }

    const saveNewMedical = () => {
      isSaving.value = true // 중복 저장 방지용
      axios
        .put('/fa/medical/new', medicalData.value)
        .then(rs => {
          toast({
            component: ToastificationContent,
            props: {
              title: '신규 라이브인포 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
          medicalData.value.liveInfoId = rs.data.id
          isSaving.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(
                error,
                '데이터를 저장하는 과정에서 오류가 발생하였습니다.',
              ),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updateMedical = () => {
      axios.patch('/fa/medical/update', medicalData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(
                error,
                '데이터를 저장하는 과정에서 오류가 발생하였습니다.',
              ),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removeMedical = () => {
      if (!medicalData.value.liveInfoId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios
            .delete(`/fa/medical/${medicalData.value.liveInfoId}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              resetMedicalInfoData()
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(
                    error,
                    '삭제하는 과정에서 오류가 발생하였습니다.',
                  ),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    const readContentFile = e => {
      if (!e) return
      if (e.type !== 'text/html') {
        pushToast('danger', '잘못된 파일 형식입니다.')
        contentFile.value.reset()
      } else {
        const reader = new FileReader()
        reader.onload = event => {
          medicalData.value.htmlContent = event.target.result
        }
        reader.readAsText(e)
      }
    }

    const downloadContent = () => {
      axios.get(`/fa/medical/${medicalData.value.liveInfoId}/content`, { responseType: 'blob' })
        .then(rs => {
          const mimeType = {
            type: "text/plain",
          }
          const blob = new Blob([rs.data], mimeType)
          const fileName = medicalData.value.title
          saveAs(blob, `${fileName}.html`)
        })
        .catch(() => {
        })
    }

    const replaceText = () => {
      if (medicalData.value.subText.length > 70) {
        medicalData.value.subText = `${medicalData.value.subText.substring(0, 70)}...`
      }
    }

    // 투표형 댓글
    const showCommentSurveySelect = ref(false)
    const commentSurveySelect = {
      close: rs => {
        showCommentSurveySelect.value = false
        if (rs) {
          medicalData.value.commentSurveyId = rs.id
          medicalData.value.commentSurveyTitle = `[${rs.id}] ${rs.title}`
        }
      },
    }

    return {
      codes,
      fetchCodes,
      onRowSelectedMedical,
      totalRecords,
      dateFormatter,
      tableColumns,
      refetchData,
      toast,
      resetMedicalInfoData,
      fetchMedical,
      fetchMedicals,
      query,
      medicalData,
      liveInfoForm,
      liveInfoFormValidFlag,
      liveInfoFormClearFlag,
      saveMedical,
      updateMedical,
      saveNewMedical,
      medicalList,
      removeMedical,
      medicalFiles,
      uploadMainImage,
      uploadSubImage,
      uploadBannerImage,
      contentFile,
      mainImgFile,
      subImgFile,
      bannerImgFile,
      showModal,
      readContentFile,
      downloadContent,
      replaceText,
      isSaving,
      bannerTargetDeptSelect,
      showBannerTargetDeptSelect,
      copyCommentContentSelect,
      showCopyCommentContentSelect,
      copyComments,
      removeCopyComments,
      showCommentSurveySelect,
      commentSurveySelect,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#medicalPreview .modal-dialog {
  max-width: 1150px;
}
</style>
