var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12" },
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "활용데이터" },
                      model: {
                        value: _vm.query.utilData,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "utilData", $$v)
                        },
                        expression: "query.utilData",
                      },
                    }),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder1",
                        placeholder: "게시기간(Fr)",
                      },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: {
                        id: "datepicker-placeholder2",
                        placeholder: "게시기간(To)",
                      },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "invoice-filter-select el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.useTypeFixed,
                        placeholder: "처방 팝업 알림",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.isInfoPopUp,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isInfoPopUp", $$v)
                        },
                        expression: "query.isInfoPopUp",
                      },
                    }),
                    _c("b-form-input", {
                      staticClass: "el-def",
                      attrs: { placeholder: "제목" },
                      model: {
                        value: _vm.query.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "title", $$v)
                        },
                        expression: "query.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 메디컬트렌드 리스트 [" +
                            _vm._s(_vm.totalRecords) +
                            "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "liveInfoId",
              items: _vm.medicalList,
              fields: _vm.tableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: { "row-selected": _vm.onRowSelectedMedical },
            scopedSlots: _vm._u([
              {
                key: "cell(liveInfoId)",
                fn: function (data) {
                  return [_vm._v(" " + _vm._s(data.value) + " ")]
                },
              },
              {
                key: "cell(utilData)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(isInfoPopUp)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value ? "O" : "X") + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(startDate)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("$dateFormatter")(data.value, "YYYY-MM-DD")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "formRules" },
        [
          _c("b-card", { attrs: { "no-body": "" } }, [
            _c(
              "div",
              { staticClass: "m-2" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "EditIcon", size: "19" },
                        }),
                        _c("h4", { staticClass: "mb-0 ml-50" }, [
                          _vm._v(" 상세 "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.resetMedicalInfoData()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "PlusSquareIcon" },
                            }),
                            _c("span", [_vm._v("신규 등록")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: {
                              disabled: _vm.isSaving,
                              variant: "primary",
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveMedical()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "SaveIcon" },
                            }),
                            _c("span", [_vm._v("저장")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-1",
                            attrs: { variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeMedical()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "Trash2Icon" },
                            }),
                            _c("span", [_vm._v("삭제")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "제목" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "제목", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "title",
                                          placeholder: "입력하세요",
                                          state:
                                            errors.length > 0 ? false : null,
                                        },
                                        model: {
                                          value: _vm.medicalData.title,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.medicalData,
                                              "title",
                                              $$v
                                            )
                                          },
                                          expression: "medicalData.title",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "사용여부",
                              "label-for": "liveinfo-is-use",
                              rules: "required",
                            },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: {
                                  id: "liveinfo-is-use",
                                  "value-field": "code",
                                  "text-field": "label",
                                },
                                model: {
                                  value: _vm.medicalData.isUse,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.medicalData, "isUse", $$v)
                                  },
                                  expression: "medicalData.isUse",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "활용데이터" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "titleType",
                                placeholder: "활용 데이터를 입력하세요",
                              },
                              model: {
                                value: _vm.medicalData.utilData,
                                callback: function ($$v) {
                                  _vm.$set(_vm.medicalData, "utilData", $$v)
                                },
                                expression: "medicalData.utilData",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "검색키워드" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "title",
                                placeholder: "키워드를 입력하세요",
                              },
                              model: {
                                value: _vm.medicalData.searchKeyWord,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.medicalData,
                                    "searchKeyWord",
                                    $$v
                                  )
                                },
                                expression: "medicalData.searchKeyWord",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "컨텐츠", "label-for": "" } },
                          [
                            _c("b-form-file", {
                              ref: "contentFile",
                              attrs: {
                                accept: "text/plain,.html",
                                "drop-placeholder": "Drop file here...",
                              },
                              on: { input: _vm.readContentFile },
                            }),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: false,
                                  expression: "false",
                                },
                              ],
                              model: {
                                value: _vm.medicalData.htmlContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.medicalData, "htmlContent", $$v)
                                },
                                expression: "medicalData.htmlContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-sm-50",
                            staticStyle: { "margin-top": "28px" },
                            attrs: {
                              variant: "primary",
                              disabled: _vm.medicalData.htmlContent === null,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showModal = true
                              },
                            },
                          },
                          [_c("span", [_vm._v("미리보기")])]
                        ),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "medicalPreview",
                              title: "HTML 미리보기",
                            },
                            model: {
                              value: _vm.showModal,
                              callback: function ($$v) {
                                _vm.showModal = $$v
                              },
                              expression: "showModal",
                            },
                          },
                          [
                            _c("iframe", {
                              ref: "previewFrame",
                              staticStyle: { "background-color": "white" },
                              attrs: {
                                srcdoc: _vm.medicalData.htmlContent,
                                frameborder: "0",
                                "max-width": "1150px",
                                width: "1115px",
                                height: "1000px",
                              },
                            }),
                          ]
                        ),
                        _vm.medicalData.liveInfoId &&
                        _vm.medicalData.htmlContent != null
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mr-sm-50",
                                staticStyle: { "margin-top": "28px" },
                                attrs: { variant: "outline-dark" },
                                on: { click: _vm.downloadContent },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "DownloadIcon" },
                                }),
                                _c("span", [_vm._v("다운로드")]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                "Main 썸네일 (※ 이미지 사이즈는 570 * 390 입니다)",
                              "label-for": "main-thumbnail-files",
                            },
                          },
                          [
                            _c("b-form-file", {
                              ref: "mainImgFile",
                              attrs: {
                                accept: "image/*",
                                placeholder: "파일 찾기",
                              },
                              on: { input: _vm.uploadMainImage },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.medicalFiles.mainImgFileName) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                "Sub 썸네일 (※ 이미지 사이즈는 640 * 140 입니다)",
                              "label-for": "sub-thumbnail-files",
                            },
                          },
                          [
                            _c("b-form-file", {
                              ref: "subImgFile",
                              attrs: {
                                accept: "image/*",
                                placeholder: "파일 찾기",
                              },
                              on: { input: _vm.uploadSubImage },
                              model: {
                                value: _vm.medicalFiles.subImgFile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.medicalFiles, "subImgFile", $$v)
                                },
                                expression: "medicalFiles.subImgFile",
                              },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.medicalFiles.subImgFileName) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label:
                                "약품정보 Banner 썸네일 (※ 이미지 사이즈는 494 * 253 입니다)",
                              "label-for": "banner-thumbnail-files",
                            },
                          },
                          [
                            _c("b-form-file", {
                              ref: "bannerImgFile",
                              attrs: {
                                accept: "image/*",
                                placeholder: "파일 찾기",
                              },
                              on: { input: _vm.uploadBannerImage },
                              model: {
                                value: _vm.medicalFiles.bannerImgFile,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.medicalFiles,
                                    "bannerImgFile",
                                    $$v
                                  )
                                },
                                expression: "medicalFiles.bannerImgFile",
                              },
                            }),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.medicalFiles.bannerImgFileName) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { label: "Sub text" } },
                          [
                            _c("validation-provider", {
                              attrs: { name: "Sub text", rules: "required" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "text",
                                          state:
                                            errors.length > 0 ? false : null,
                                          placeholder:
                                            "공백포함 70자까지 노출됩니다.",
                                          maxlength: "73",
                                        },
                                        on: { input: _vm.replaceText },
                                        model: {
                                          value: _vm.medicalData.subText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.medicalData,
                                              "subText",
                                              $$v
                                            )
                                          },
                                          expression: "medicalData.subText",
                                        },
                                      }),
                                      _c(
                                        "small",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mt-1 mb-2",
                            staticStyle: { "margin-right": "50px" },
                            attrs: { label: "메인 게시여부" },
                          },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: { name: "isMainContent" },
                                model: {
                                  value: _vm.medicalData.isMainContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.medicalData,
                                      "isMainContent",
                                      $$v
                                    )
                                  },
                                  expression: "medicalData.isMainContent",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: "true" },
                                  },
                                  [_vm._v(" 게시 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: "false" },
                                  },
                                  [_vm._v(" 게시 안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-2", attrs: { cols: "12", md: "3" } },
                      [
                        _c(
                          "b-form-group",
                          { staticStyle: { "margin-right": "50px" } },
                          [
                            _c("label", [_vm._v("댓글 기능")]),
                            _c(
                              "b-form-radio-group",
                              {
                                attrs: { name: "isComment" },
                                model: {
                                  value: _vm.medicalData.isComment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.medicalData, "isComment", $$v)
                                  },
                                  expression: "medicalData.isComment",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-primary",
                                    attrs: { value: true },
                                  },
                                  [_vm._v(" 사용 ")]
                                ),
                                _c(
                                  "b-form-radio",
                                  {
                                    staticClass: "custom-control-secondary",
                                    attrs: { value: false },
                                  },
                                  [_vm._v(" 사용안함 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.medicalData.isComment
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12", md: "5" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "comment-survey-title" } },
                                  [_vm._v("투표형 댓글 투표지 선택")]
                                ),
                                _c(
                                  "b-badge",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName:
                                          "v-b-tooltip.hover.v-secondary",
                                        modifiers: {
                                          hover: true,
                                          "v-secondary": true,
                                        },
                                      },
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.right",
                                        modifiers: { hover: true, right: true },
                                      },
                                    ],
                                    staticStyle: {
                                      padding: "0px",
                                      "margin-left": "5px",
                                    },
                                    attrs: {
                                      variant: "light-secondary",
                                      title:
                                        "지정: 투표형 댓글 / 미지정: 일반 댓글",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "InfoIcon" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.medicalData.isAnswer
                                  ? _c(
                                      "label",
                                      {
                                        staticStyle: {
                                          "margin-left": "15px",
                                          color: "#ea5455",
                                        },
                                        attrs: { for: "comment-survey-title" },
                                      },
                                      [
                                        _vm._v(
                                          "참여자가 있어 다른 투표지를 등록할 수 없습니다."
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("b-form-input", {
                                  attrs: {
                                    id: "comment-survey-title",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.medicalData.commentSurveyTitle,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.medicalData,
                                        "commentSurveyTitle",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "medicalData.commentSurveyTitle",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.medicalData.isComment
                      ? _c(
                          "b-col",
                          {
                            staticClass: "mb-50",
                            attrs: { cols: "12", md: "4" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-0 mt-md-2",
                                attrs: {
                                  disabled: _vm.medicalData.isAnswer,
                                  variant: "outline-primary",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    _vm.showCommentSurveySelect = true
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-50",
                                  attrs: { icon: "SearchIcon" },
                                }),
                                _c("span", [_vm._v("투표지 검색")]),
                              ],
                              1
                            ),
                            _vm.medicalData.commentSurveyId
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "ml-1 mt-0 mt-md-2",
                                    attrs: { variant: "outline-danger" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.medicalData.commentSurveyId = null
                                        _vm.medicalData.commentSurveyTitle =
                                          null
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "Trash2Icon" },
                                    }),
                                    _c("span", [_vm._v("투표지 제거")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "MessageSquareIcon", size: "19" },
                      }),
                      _c(
                        "h4",
                        {
                          staticClass: "mb-0 ml-50",
                          staticStyle: { "min-width": "120px" },
                        },
                        [_vm._v(" 댓글 가져오기 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-1",
                          attrs: {
                            disabled: !_vm.medicalData.commentCopyDate,
                            variant: "outline-secondary",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeCopyComments()
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "Trash2Icon" },
                          }),
                          _c("span", [_vm._v("초기화")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "컨텐츠 선택",
                            "label-for": "medical-banner-target-dept",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "medical-banner-target-dept",
                              readonly: "",
                            },
                            model: {
                              value: _vm.medicalData.commentCopyContentTitle,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.medicalData,
                                  "commentCopyContentTitle",
                                  $$v
                                )
                              },
                              expression: "medicalData.commentCopyContentTitle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start mt-50",
                      attrs: { cols: "6", md: "6" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showCopyCommentContentSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "SearchIcon" },
                          }),
                          _c("span", [_vm._v("목록")]),
                        ],
                        1
                      ),
                      _vm.medicalData.commentCopyContentId &&
                      !_vm.medicalData.commentCopyDate
                        ? _c(
                            "b-button",
                            {
                              staticClass: "ml-1",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.copyComments()
                                },
                              },
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "mr-50",
                                attrs: { icon: "RefreshCwIcon" },
                              }),
                              _c("span", [_vm._v("가져오기")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.medicalData.commentCopyDate
                        ? _c("label", { staticClass: "ml-1" }, [
                            _vm._v(
                              "(가져오기완료: " +
                                _vm._s(_vm.medicalData.commentCopyDate) +
                                ")"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "TargetIcon", size: "19" },
                      }),
                      _c(
                        "h4",
                        {
                          staticClass: "mb-0 ml-50",
                          staticStyle: { "min-width": "120px" },
                        },
                        [_vm._v(" 타겟 진료과 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진료과",
                            "label-for": "medical-banner-target-dept",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "진료과", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "medical-banner-target-dept",
                                        readonly: "",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value:
                                          _vm.medicalData.bannerTargetDeptNames,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.medicalData,
                                            "bannerTargetDeptNames",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "medicalData.bannerTargetDeptNames",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showBannerTargetDeptSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "PlusSquareIcon" },
                          }),
                          _c("span", [_vm._v("목록")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("live-info-form", {
        attrs: {
          "liveinfo-id": _vm.medicalData.liveInfoId,
          "content-type": 2,
          "clear-flag": _vm.liveInfoFormClearFlag,
          "valid-flag": _vm.liveInfoFormValidFlag,
        },
        on: { callback: _vm.liveInfoForm.callback },
      }),
      _c("medi-dept-select", {
        attrs: {
          "show-medi-dept-select": _vm.showBannerTargetDeptSelect,
          "selected-value": _vm.medicalData.bannerTargetDeptCodes,
        },
        on: { close: _vm.bannerTargetDeptSelect.close },
      }),
      _c("copy-comment-content-select", {
        attrs: { "show-content-select": _vm.showCopyCommentContentSelect },
        on: { close: _vm.copyCommentContentSelect.close },
      }),
      _c("comment-survey-select", {
        attrs: { "show-comment-survey-select": _vm.showCommentSurveySelect },
        on: { close: _vm.commentSurveySelect.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }